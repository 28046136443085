import { useCallback } from "react"
import { navigate } from "gatsby"
import { useLocation } from '@reach/router'
import queryString from "query-string"

const useQueryState = (query, initialValue) => {

  const location = useLocation()

  const setQuery = useCallback(
    value => {
      const existingQueries = new URLSearchParams(location.search)
      const params = Object.fromEntries(existingQueries)

      const qs = queryString.stringify(
        { ...existingQueries, [query]: value },
        { skipNull: true }
      )

      const newURL = `${location.pathname}?${qs}`
      navigate(`${location.pathname}?${qs}`, { replace: false })
    },
    [navigate, location, query]
  )

  const currentQuery = queryString.parse(location.search, { ignoreQueryPrefix: true })[query]
  const stateValue = currentQuery ? currentQuery : initialValue
  
  return [
    stateValue,
    setQuery,
  ]
}

export default useQueryState