import React, { useEffect, useState } from "react"
import { useLocation } from '@reach/router'
import useQueryState from 'components/utils/useQueryState'

import Box from '@mui/material/Box'
import Button from "@mui/material/Button"
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Snackbar from '@mui/material/Snackbar'
import TextField from '@mui/material/TextField'

import { TemplateContextProvider } from 'components/post/TemplateProvider'

import queryString from "query-string"

import "styles/embed.scss"

const Embed = ({ data, ...props }) => {
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)

  const [page, setPage] = useQueryState('page', 'jack-london-neighborhood-challenge')

  const widgetOptions = [
    { key: 'SingCardsBlock', value: 'SingCardsBlock', text: 'Place Cards' },
    { key: 'MapBlock', value: 'MapBlock', text: 'Map' },
    { key: 'AppStoreCallout', value: 'AppStoreCallout', text: 'Download App' },
  ]

  const [selectedSection, setSelectedSection] = useState(widgetOptions[0].value)
  const [selectedDevice, setSelectedDevice] = useState('web')
  const [height, setHeight] = useState(520)

  const [iframe, setIFrame] = useState(null)
  const isDev = process.env.NODE_ENV !== 'production'
  const domain = isDev ? `http://localhost:8080` : `https://vibemap.com`

  useEffect(() => {
    const src = `${domain}/${page}?embedded=1&section=${selectedSection}&index=1`

    const iframe = `<iframe
      allowtransparency="true"
      allowfullscreen="true"
      frameborder="no"
      height=${height}
      onload="resizeIframe(this)"
      style="width: 100%;"
      scrolling="no"
      title="Vibemap Widget"
      src="${src}">
    </iframe>`

    setIFrame(iframe)
  }, [setIFrame, page, selectedDevice, selectedSection])

  const deviceOptions = [
    { key: 'mobile', value: 'mobile', text: 'Mobile' },
    { key: 'web', value: 'web', text: 'Web' },
  ]

  const handleChange = (event) => {
    const value = event?.target?.value
    console.log('handleChange event ', event, value)

    if (value == 'MapBlock') setHeight(800)
    if (value == 'SingCardsBlock') setHeight(620)

    setSelectedSection(value)
  }

  const handleDeviceChange = (event) => {
    const value = event?.target?.value
    setSelectedDevice(value)
  }

  const handlePageChange = (event) => {
    const value = event.target.value
    setPage(value)
  }

  const [showMessage, setShowMessage] = useState(false)
  const copyToClipboard = () => {
    setShowMessage(true)
    navigator.clipboard.writeText(iframe)
  }


  const Title = props => (
    <h1 className="title">{props.text}</h1>
  )

  return (
    <div className={`embed`}>
      <div>
        <div className="box">
          <Title text="Embed Options:" />
          <Box component="form"
            noValidate
            autoComplete="off">
            <FormControl sx={{ m: 1, minWidth: 120 }} >
              <Select
                placeholder='Select widget'
                onChange={handleChange}
                value={selectedSection}>
                {widgetOptions.map(item => <MenuItem value={item.key}>{item.text}</MenuItem> )}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }} >
              <Select
                placeholder='Select device'
                onChange={handleDeviceChange}
                value={selectedDevice}>
                {deviceOptions.map(item => <MenuItem value={item.key}>{item.text}</MenuItem> )}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }} >
              <TextField
                id="page"
                label="Page to embed"
                value={page}
                onChange={handlePageChange} />
            </FormControl>
          </Box>
          <span>{`Preview of Widget ${selectedSection} on ${selectedDevice}`}</span>
        </div>

        <div className={`box ${selectedDevice}`}>

          <div key={Date()} style={{ 'height' : height }}
            dangerouslySetInnerHTML={{
              __html: iframe? iframe: ""
            }}
          />

        </div>

        <div className={'box'}>
          <span>Copy this code:</span>
          <Button
            variant="contained"
            color="primary"
            onClick={copyToClipboard}
          >
            Copy
          </Button>
          <Snackbar
            open={showMessage}
            onClose={() => setShowMessage(false)}
            autoHideDuration={2000}
            message="Copied to clipboard" />

          <code>{iframe}</code>
        </div>
      </div>
    </div>
  )
}

export default Embed